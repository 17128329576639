(function ($) {
    var $links = $('a');

    $links.each(function() {
        $(this).on('click', function(e){
            e.preventDefault();
            console.log('Link', $(this).attr("href"));
            window.location=$(this).attr("href");
            return false;
        });
    });
})(jQuery);